'use client';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '../utils/cn';
import { type ElementType, forwardRef, useState } from 'react';
import { LoaderIcon } from 'lucide-react';

const buttonVariants = cva(
    'inline-flex items-center justify-center rounded-md text-sm font-semibold ring-offset-background transition duration-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 active:scale-[.98] disabled:pointer-events-none disabled:opacity-50',
    {
        variants: {
            variant: {
                default: 'bg-primary text-white hover:bg-primary/90',
                destructive:
                    'bg-destructive text-destructive-foreground hover:bg-destructive/80 dark:hover:bg-red-800',
                warning: 'bg-warning text-warning-foreground hover:bg-warning/80',
                outline:
                    'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
                secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
                ghost: 'hover:bg-accent hover:text-accent-foreground',
                link: 'text-primary underline-offset-4 hover:underline',
                success: 'bg-success text-success-foreground hover:bg-success/90',
                fancy: ' bg-gradient-to-r from-primary via-purple-500 to-pink-700 text-white shadow-lighterPrimary  ease-in-out hover:text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 ',
            },
            size: {
                default: 'h-10 px-4 py-2',
                sm: 'h-9 rounded-md px-3',
                xs: 'h-7 rounded-md px-2 py-1 text-xs',
                lg: 'h-11 rounded-md px-8',
                fullWidth: 'h-13 w-full rounded-md px-8',
                icon: 'size-10',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    }
);

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
    /**
     * If true, shows a loading indicator and disables the button.
     */
    loading?: boolean;
    /**
     * The text/React node to show when the button is in the loading state.
     */
    loadingText?: React.ReactNode;
    /**
     * If true, the button will automatically set the loading state when clicked while the onClick handler is resolving.
     */
    autoSetLoadingState?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            variant,
            size,
            asChild = false,
            loading = false,
            loadingText,
            autoSetLoadingState,
            ...props
        },
        ref
    ) => {
        const Comp: ElementType = asChild ? Slot : 'button';
        const [autoLoading, setAutoLoading] = useState(false);

        return (
            <Comp
                className={cn(buttonVariants({ variant, size, className }))}
                ref={ref}
                {...{
                    ...props,
                    disabled:
                        (autoLoading || loading) && props.disabled !== false
                            ? true
                            : props.disabled,
                    onClick: async (e) => {
                        if (autoSetLoadingState === true) {
                            try {
                                setAutoLoading(true);
                                await props.onClick?.(e);
                            } finally {
                                setAutoLoading(false);
                            }
                        } else {
                            await props.onClick?.(e);
                        }
                    },
                }}
            >
                {loading || autoLoading ? (
                    <div className="flex flex-row items-center gap-1">
                        <LoaderIcon className="size-auto animate-spin opacity-70" />
                        {loadingText !== undefined && loadingText}
                        <span className="sr-only">Loading...</span>
                    </div>
                ) : (
                    props.children
                )}
            </Comp>
        );
    }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
