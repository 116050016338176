import { cn } from '@/utils/cn';

interface SmallProps {
    children: React.ReactNode;
    settings?: React.HTMLAttributes<HTMLElement>;
    className?: string;
}

export function Small({ children, settings, className }: SmallProps) {
    return (
        <small className={cn('text-sm font-medium leading-none', className)} {...settings}>
            {children}
        </small>
    );
}
