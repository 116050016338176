'use client';

import { TailwindConfig } from '@/utils/tailwind';
import type { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { usePathname, useRouter } from 'next/navigation';
import NextTopLoader from 'nextjs-toploader';
import { done, start } from 'nprogress';
import { useCallback, useEffect } from 'react';

/**
 * This is a wrapper around the NextJS TopLoader component
 * and Nextjs router that starts and stops the top loader
 * when using router to change pages
 * @returns A router object with a push function that starts the top loader
 */
export const useTopRouter = () => {
    const router = useRouter();
    const pathname = usePathname();

    const replace = useCallback(
        (href: string, options?: NavigateOptions) => {
            href !== pathname && start();
            router.replace(href, options);
        },
        [router, pathname]
    );

    const push = useCallback(
        (href: string, options?: NavigateOptions) => {
            href !== pathname && start();
            router.push(href, options);
        },
        [router, pathname]
    );

    return {
        ...router,
        replace,
        push,
    };
};

export default function TopLoader() {
    const pathname = usePathname();

    useEffect(() => {
        done();
    }, [pathname]);

    return <NextTopLoader color={TailwindConfig.theme.colors.primary} height={2} />;
}
