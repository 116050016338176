import { cn } from '@/utils/cn';

interface H2Props {
    children: React.ReactNode;
    settings?: React.HTMLAttributes<HTMLHeadingElement>;
    className?: string;
}

export function H2({ children, settings, className }: H2Props) {
    return (
        <h2
            className={cn(
                'scroll-m-20 pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0',
                className,
            )}
            {...settings}
        >
            {children}
        </h2>
    );
}
